/**
 * 传入["广东省", "广州市", "番禺区"] 得到 '广州'
 * @param value
 * @returns {*}
 */
export function beautifyCity(value) {
  if (!Array.isArray(value)) {
    value = value.split(',')
  }
  const cityFullName = value[1] || value[0]
  if (cityFullName.endsWith('市')) {
    return cityFullName.slice(0, cityFullName.length - 1)
  }
  if (cityFullName.endsWith('特别行政区')) {
    return cityFullName.slice(0, cityFullName.length - 5)
  }
  return cityFullName
}

/**
 * 传入["广东省", "广州市", "番禺区"] 得到 '番禺'
 * @param value
 * @returns {*}
 */
export function beautifyDistrict(value) {
  if (!Array.isArray(value)) {
    value = value.split(',')
  }
  const cityFullName = value[2] || value[1]

  if (cityFullName.endsWith('特别行政区')) {
    return cityFullName.slice(0, cityFullName.length - 5)
  } else if (cityFullName.endsWith('区')) {
    return cityFullName.slice(0, cityFullName.length - 1)
  }
  return cityFullName
}

/**
 * 传入["广东省", "广州市", "番禺区"] 得到 '广州·番禺'
 * @param arr
 * @returns {string}
 */
export function beautifyJobCity(arr = []) {
  let city = beautifyCity(arr)
  let district = beautifyDistrict(arr)
  return city !== district ? city + '·' + district : city
}
