<template>
  <div class="page flex" v-loading="!resume && !empty">
    <div v-if="resume" class="left flex-col" style="flex-shrink: 0">
      <div class="resume-item">
        <el-avatar
          :src="fixImageUrl(resume.base.avatar)"
          :size="80" style="cursor: pointer"
          @click.native="$previewImage([resume.base.avatar])"
        ></el-avatar>
      </div>
      <div class="resume-item flex-col" style="margin-top: 12px">
        <h1 class="name">{{ resume.base.nickname }}</h1>
        <p class="intro" style="margin-top: 4px">
          {{
            `${workAge}经验 | ${resume.base.years}岁 | ${
              resume.edus && resume.edus[0] && resume.edus[0].history || '学历未填写'
            }`
          }}
        </p>
      </div>

      <h3 class="config-section-title">个人优势</h3>

      <div class="resume-item">
        <p class="advantage">{{ resume.advantage | placeholder }}</p>
      </div>

      <h3 class="config-section-title">求职期望</h3>

      <div class="resume-item">
        <p class="advantage">
          {{ intentionJob }}
          <span class="salary"
            >{{ resume.intention.min_intention_wage }}-{{
              resume.intention.max_intention_wage
            }}k</span
          >
        </p>

        <p class="intro" style="margin-top: 8px; font-size: 13px">
          {{ beautifyCity(resume.intention.intention_city) }}
        </p>
        <p
          v-if="intentionCategory"
          class="intro"
          style="margin-top: 4px; font-size: 13px"
        >
          {{ intentionCategory }}
        </p>
      </div>

      <h3 class="config-section-title">求职状态</h3>
      <div class="resume-item">
        <p class="status">{{ resume.work_status }}</p>
      </div>

      <h3 class="config-section-title">求职类型</h3>
      <div class="resume-item">
        <p class="status">{{ resume.career_category.career_category_name }}</p>
      </div>
    </div>
    <div v-if="resume" class="divider"></div>
    <div v-if="resume" class="right flex-1">
      <div
        class="flex-align-center config-section-title"
        style="border-left: none"
      >
        <img
          class="icon"
          :src="
            require('@/modules/organization-admin/assets/images/resume/education.png')
          "
        />教育经历
      </div>

      <p v-if="!resume.edus.length" class="">{{ '' | placeholder }}</p>

      <div
        :class="{
          multiple: resume.edus.length > 1,
          last: eduI === resume.edus.length - 1,
        }"
        v-for="(edu, eduI) in resume.edus"
        :key="edu.id"
        class="resume-item edu"
      >
        <div class="flex-between">
          <div class="school">{{ edu.graduate }}</div>
          <div class="range">{{ edu.start_year }}-{{ edu.end_year }}</div>
        </div>
        <div class="major">{{ edu.history }}·{{ edu.profession }}</div>
        <p class="desc">{{ edu.experience }}</p>
      </div>

      <div
        class="flex-align-center config-section-title"
        style="border-left: none"
      >
        <img
          class="icon"
          :src="
            require('@/modules/organization-admin/assets/images/resume/job.png')
          "
        />工作经历
      </div>

      <p v-if="!resume.jobs.length" class="">{{ '' | placeholder }}</p>

      <div
        :class="{
          multiple: resume.jobs.length > 1,
          last: eduI === resume.jobs.length - 1,
        }"
        v-for="(edu, eduI) in resume.jobs"
        :key="edu.id"
        class="resume-item job"
      >
        <div class="flex-between">
          <div class="company">{{ edu.job_company }}</div>
          <div class="range">{{ edu.start_year.replace('-', '.') }}-{{ edu.end_year.replace('-', '.') }}</div>
        </div>
        <div class="title">{{ edu.job_career }}</div>
        <p class="desc">{{ edu.job_desc }}</p>
      </div>

      <div
        class="flex-align-center config-section-title"
        style="border-left: none"
      >
        <img
          class="icon"
          :src="
            require('@/modules/organization-admin/assets/images/resume/education.png')
          "
        />项目经历
      </div>

      <p v-if="!resume.projects.length" class="">{{ '' | placeholder }}</p>

      <div
        :class="{
          multiple: resume.projects.length > 1,
          last: eduI === resume.projects.length - 1,
        }"
        v-for="(edu, eduI) in resume.projects"
        :key="edu.id"
        class="resume-item project"
      >
        <div class="flex-between">
          <div class="project-name">{{ edu.project_name }}</div>
          <div class="range">{{ edu.start_year.replace('-', '.') }}-{{ edu.end_year.replace('-', '.') }}</div>
        </div>
        <div class="role">{{ edu.project_role }}</div>
        <p class="desc">{{ edu.project_desc }}</p>
      </div>
    </div>

    <div v-if="empty" class="flex-center" style="width: 100%;">
      <el-empty description="简历未创建" />
    </div>
  </div>
</template>

<script>
import { getResumeDetail } from '../../api/member/resume-detail'
import { beautifyCity } from '../../js/regionUtil'

export default {
  props: {
    data: {
      type: String,
    },
  },
  data() {
    return {
      resume: null,
      empty: false, // 简历不存在
    }
  },
  computed: {
    workAge() {
    //   return this.resume?.base?.work_start_time
    //     ? this.calculateAge(this.resume.base.work_start_time) + '年'
      return this.resume?.base.work_years ? `${this.resume.base.work_years}年`
        : '1年以下'
    },
    intentionJob() {
      let arr = this.resume.intention.intention_career.split(',')
      return arr[arr.length - 1]
    },
    intentionCategory() {
      let arr = JSON.parse(
        JSON.stringify(this.resume.intention.intention_career)
      ).split(',')
      // arr.pop()
      return arr.length > 1 ? arr[0] : ''
    },
  },
  filters: {
    placeholder(value) {
      return value || '未填写'
    },
  },
  created() {
    this.getDetail()
  },
  methods: {
    beautifyCity,
    getDetail() {
      const paramsData = this.$deCode(this.$route.params.data)

      getResumeDetail({
        user_id: paramsData.id,
      })
        .then(({ data }) => {
          this.resume = data
        })
        .catch(() => {
          this.empty = true
        })
    },
    // 计算工龄
    calculateAge(birthday) {
      const ageDifMs = Date.now() - new Date(birthday).getTime()
      const ageDate = new Date(ageDifMs)
      return Math.abs(ageDate.getUTCFullYear() - 1970)
    },
  },
}
</script>

<style lang="scss" scoped>
.page {
  min-height: 600px;
  align-items: stretch;
  margin: -$space;

  .left,
  .right {
    padding: $space;
  }

  .left {
    min-width: 305px;
    max-width: 500px;
  }

  .right {
    padding-left: $space * 1.7 !important;
    max-width: calc(#{$space * 2} + 424px + #{$space});
  }

  .config-section-title {
    margin-top: $space;
    margin-bottom: 12px;

    &.flex-align-center {
      font-weight: bold;
      margin-left: -$space;
    }

    .icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }

  .name {
    color: #000000;
    font-size: 22px;
    font-weight: normal;
    line-height: 33px;
  }

  .intro {
    color: #808080;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }

  .advantage {
    color: #1a1a1a;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }

  .salary {
    color: #ff6c1a;
    font-size: 13px;
    margin-left: 8px;
    display: inline-block;
  }

  .status {
    color: #000000;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .divider {
    width: 1px;
    flex-shrink: 0;
    background-color: #eff2f6;
  }

  .edu,
  .job,
  .project {
    padding-left: $space * 0.7;
    position: relative;

    &.multiple {
      &:not(.last) {
        &:after {
          content: '';
          display: block;
          border-left: 0.5px dashed rgba($primary, 0.6);
          position: absolute;
          left: -5px;
          top: 5px;
          height: calc(100% + 16px);
        }
      }

      &:before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        flex-shrink: 0;
        background-color: #1a75ff;
        border-radius: 50%;
        position: absolute;
        left: -8px;
        top: 6px;
      }
    }

    & + .edu,
    & + .job,
    & + .project {
      margin-top: 16px;
    }

    .desc {
      white-space: pre-wrap;
    }
  }

  .edu {
    .school {
      color: #000000;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .range {
      color: #808080;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .major {
      color: #808080;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 9px;
    }

    .desc {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 0;
      color: #808080;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.2px;
      margin-top: 8px;
    }
  }

  .job {
    .company {
      color: #000000;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .range {
      color: #00000099;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .title {
      color: #00000099;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 8px;
    }

    .desc {
      color: #808080;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20.8px;
      margin-top: 8px;
    }
  }

  .project {
    .project-name {
      color: #000000;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .role {
      color: #00000099;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 8px;
    }

    .desc {
      color: #808080;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20.8px;
      margin-top: 8px;
    }

    .range {
      color: #00000099;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
</style>
